import React from "react";
import { Link } from "gatsby";


export default function Babujicontent() {
  return (
    <section className="lalajicontentpage pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color"> Babuji Maharaj </h4>
            <p className="theme-p-tag"> 1899 A.D.– 1983 A.D. </p>
            <p className="theme-p-tag">
              {" "}
              Babuji was born on 30th April 1899 in the north Indian town of
              Shahjahanpur (Uttar Pradesh). From an early age, he displayed a
              craving for spiritual realization that overshadowed all other
              interests.
            </p>
            <p className="theme-p-tag">
              {" "}
              He held the position of clerk in the district court of
              Shahjahanpur for more than thirty years. He was married at the age
              of nineteen and his wife, Bhagwati, bore him two daughters and
              four sons before her death in 1949.
            </p>
            <p className="theme-p-tag">
              {" "}
              In June 1922, at the age of twenty-two, he met Lalaji, who
              recognized Babuji as the person who had appeared in his dream
              years before as his successor.
            </p>
            <p className="theme-p-tag">
              {" "}
              Though there were very few meetings between guru and disciple
              before Lalaji left his physical body in 1931, Babuji was in
              perpetual remembrance of Lalaji and insisted that he could not
              live for a second without his inner guidance. Babuji believed that
              evolution of consciousness is the birthright of all and should be
              offered freely to sincere seekers everywhere. He believed that a
              true Guru is the ultimate servant, and he lived his life serving
              all, irrespective of caste, creed, religion, gender or
              nationality. He taught that material and spiritual life go hand in
              hand, like the two wings of a bird, and that the normal life of a
              householder is the best environment in which to learn the twin
              virtues of love and sacrifice. Accordingly, he simplified and
              perfected the system of raja yoga so that everyone could practice
              and benefit from it.
            </p>
            <p className="theme-p-tag">
              {" "}
              He advised his followers not to be discouraged by their faults and
              shortcomings, but to let go of mistakes, resolving not to repeat
              them again. He reminded them that it is in the present that we
              develop our character and thus create a brighter future.
            </p>
            <p className="theme-p-tag">
              {" "}
              Babuji was, by nature, the humblest of people and had immense
              faith in his guru. He was confident that seekers of every culture
              and nationality would embrace the simple and effective practices
              that he offered. In 1972, he took the Heartfulness method to
              Europe and America, accompanied by his attendant, Shri
              Parthasarathi Rajagopalachari of Chennai. Parthasarathi, known to
              his associates as{" "}
              <Link
                to="https://heartfulness.org/en/chariji/"
                target="_blank"
                rel="noreferrer" className="text-decore-none"
              >
                <span className="orange-text"> Chariji, </span>
              </Link>{" "}
              was later chosen to succeed Babuji as the third guru in the
              Heartfulness tradition.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
