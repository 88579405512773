import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Babujicontent from "../components/subpages/babujicontent";

import Babujibreadcreumb from "../components/subpages/babujibreadcumb";

// markup
const babujiPage = () => {
  return (
    <div>
      <Seo title="lalaji" />

      <Layout isSticky>
        <section>
          <Babujibreadcreumb />
        </section>

        <section>
          <Babujicontent />
        </section>
      </Layout>
    </div>
  );
};

export default babujiPage;
